<template>
	<TheHomeSecond />
</template>

<script>
//import { doNotLetUserGetBack } from '../doNotLetUserGoBack';
const TheHomeSecond = () => import('./TheHomeSecond.vue');

export default {
	components: {
		TheHomeSecond,
	},

	created() {
		//doNotLetUserGetBack('main');

		//get back after ssl
		/* this.$router.push({ name: 'main' }); */
		sessionStorage.IsFromMane = true;
		
	},
};
</script>
